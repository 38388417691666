import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { router } from '@/router';
import { ref } from 'vue';
import DepositRecord from '@/components/DepositRecord/DepositRecord.vue';
import WithdrawalRecord from '@/components/WithdrawalRecord/WithdrawalRecord.vue';
import TransactionHistory from '@/components/TransactionHistory/TransactionHistory.vue';
export default {
  __name: 'historyBill',
  setup(__props) {
    const active = ref(0);
    const back = () => {
      router.back();
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        "left-arrow": "",
        title: _ctx.$t('billing_history'),
        placeholder: "",
        fixed: "",
        onClickLeft: back
      }, null, 8, ["title"]), _createElementVNode("div", null, [_createVNode(_component_van_tabs, {
        active: active.value,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
        color: "#FC72FF",
        sticky: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tab, {
          title: _ctx.$t('deposit')
        }, {
          default: _withCtx(() => [_createVNode(DepositRecord)]),
          _: 1
        }, 8, ["title"]), _createVNode(_component_van_tab, {
          title: _ctx.$t('withdraw')
        }, {
          default: _withCtx(() => [_createVNode(WithdrawalRecord)]),
          _: 1
        }, 8, ["title"]), _createVNode(_component_van_tab, {
          title: _ctx.$t('billing_record')
        }, {
          default: _withCtx(() => [_createVNode(TransactionHistory)]),
          _: 1
        }, 8, ["title"])]),
        _: 1
      }, 8, ["active"])])], 64);
    };
  }
};