import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/copy.png';
const _withScopeId = n => (_pushScopeId("data-v-b2ef1ccc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "recharge-item__head"
};
const _hoisted_2 = {
  class: "recharge-item__row"
};
const _hoisted_3 = {
  class: "recharge-item__row"
};
const _hoisted_4 = {
  class: "recharge-item__hash"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 0,
  class: "recharge-item__row"
};
const _hoisted_7 = {
  class: "recharge-item__row"
};
import { ref } from 'vue';
import { historyBill } from '@/http';
import { useClipboard } from '@vueuse/core';
import { showToast } from 'vant';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'WithdrawalRecord',
  setup(__props) {
    const {
      t
    } = useI18n();
    const page = ref(1);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const stateText = [t('under_review'), t('completed'), t('rejected')];
    const {
      copy,
      isSupported
    } = useClipboard();
    const _copy = val => {
      copy(val).then(() => {
        if (!isSupported.value) {
          return showToast(t('current_browser_does_not_support_copy'));
        }
        showToast(t('copy_successful'));
      });
    };
    const onLoad = () => {
      loading.value = true;
      historyBill({
        page: page.value,
        type: 'withdraw',
        perPage: 10
      }).then(({
        data
      }) => {
        loading.value = false;
        if (data.current_page === 1) {
          list.value = data.data;
        } else {
          list.value = list.value.concat(data.data);
        }
        finished.value = data.current_page >= data.last_page;
        if (!finished.value) {
          page.value++;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createBlock(_component_van_list, {
        loading: loading.value,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
        finished: finished.value,
        "finished-text": _ctx.$t('no_more'),
        onLoad: onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.id,
            class: "recharge-item"
          }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", null, _toDisplayString(item.currency_type), 1), _createElementVNode("div", {
            class: _normalizeClass(['recharge-item__active' + item.state])
          }, _toDisplayString(stateText[item.state]), 3)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('withdraw_amount')), 1), _createElementVNode("span", null, _toDisplayString(item.real_amount), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('withdrawal_address')), 1), _createElementVNode("span", _hoisted_4, [_createTextVNode(_toDisplayString(item.other) + " ", 1), _createElementVNode("img", {
            class: "recharge-item__copy",
            src: _imports_0,
            alt: "",
            onClick: $event => _copy(item.other)
          }, null, 8, _hoisted_5)])]), item.state === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('rejection_reason')), 1), _createElementVNode("span", null, _toDisplayString(item.reason), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('time')), 1), _createElementVNode("span", null, _toDisplayString(item.created_at), 1)])]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "finished-text"]);
    };
  }
};